import React from 'react';
import { useSelector } from 'react-redux';
import Image from '../Image/Image';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { InternalLink } from '../form-elements/Link';

function Logo() {
    const markenStandort = useSelector(markenStandortSelector);
    return (
        <InternalLink to="/">
            {markenStandort.isTechno ? (
                <Image
                    src="https://techno-images.s3.eu-central-1.amazonaws.com/icons/aps_logo_national.jpg"
                    alt={`${markenStandort.title} Logo`}
                    resizeHeight={40}
                />
            ) : (
                markenStandort.gesellschafterNr &&
                markenStandort.standortNr && (
                    <Image
                        src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Logo.jpg`}
                        alt={`${markenStandort.title} Logo`}
                        resizeHeight={40}
                    />
                )
            )}
        </InternalLink>
    );
}

export default Logo;
